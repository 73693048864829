import React, { FC } from "react";
import { ImageProps } from "./../generated-types";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Box, Text as ChakraText } from "@chakra-ui/react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { imageSizes, themable } from "~/foundation/Theme";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { ImageSizeParameters } from "@sitecore-jss/sitecore-jss-react";

enum ImageRatio {
	Default,
	UncroppedWide,
	UncroppedNarrow
}

const Image: FC<ImageProps> = ({ fields, rendering, params }) => {
	const { sitecoreContext } = useSitecoreContext();
	let imageRatio = ImageRatio.Default;
	let srcSet: ImageSizeParameters[] = [imageSizes.imageModule.default.sm, imageSizes.imageModule.default.md, imageSizes.imageModule.default.lg, imageSizes.imageModule.default.xl];
	let sizes = "(min-width: 1920px) 1504px, (min-width: 1440px) 1312px, (min-width: 1280px) 1024px, 100vw";
	const moduleSpacingNone = params.moduleSpacing === "none";

	if (params.imageRatio === "1") {
		imageRatio = ImageRatio.UncroppedWide;
		srcSet = [imageSizes.imageModule.uncroppedWide.md, imageSizes.imageModule.uncroppedWide.lg, imageSizes.imageModule.uncroppedWide.lg, imageSizes.imageModule.uncroppedWide.xl];
	}
	if (params.imageRatio === "2") {
		imageRatio = ImageRatio.UncroppedNarrow;
		srcSet = [imageSizes.imageModule.uncroppedNarrow.md, imageSizes.imageModule.uncroppedNarrow.lg, imageSizes.imageModule.uncroppedNarrow.xl];
		sizes = "((min-width: 1440px) 710px, (min-width: 1920px) 826px, 100vw";
	}

	return (
		<Ribbon
			pt={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			pb={moduleSpacingNone ? "0" : ["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
		>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper py={["0", null]} px={imageRatio === ImageRatio.UncroppedNarrow ? ["var(--chakra-sizes-pageMarginSM)", null, "16", null, "32"] : ['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}>
				<Box
					mx={imageRatio !== ImageRatio.UncroppedNarrow ? "0" : "auto"}
					w={imageRatio === ImageRatio.UncroppedNarrow ? ["100%", null, "60%"] : "100%"}>
					{(fields?.source?.value?.src || sitecoreContext.pageEditing) &&
						<Box
							aspectRatio={imageRatio === ImageRatio.Default ? 16 / 9 : "initial"}
							sx={{
								".LazyLoad": {
									borderRadius: "lg",
									borderBottomRightRadius: "0",
									overflow: "hidden"
								}
							}}>
							<sc.AdvanceImage
								fields={fields}
								itemId={rendering.dataSource}
								fieldName="source"
								srcSet={srcSet}
								sizes={sizes}
								sx={{
									objectFit: "cover",
									objectPosition: "center center",
									minWidth: "100%",
									minHeight: "100%",
								}}
							/>
						</Box>
					}
					{(fields?.captionText?.value || sitecoreContext.pageEditing) &&
					<ChakraText variant="footerBody" maxW="482" mt={["4", null, "8"]} ms={["4", null, "8", null, "16"]} me={["4", null, "8", "0"]}>
						<sc.Text field={fields?.captionText} />
					</ChakraText>
					}
				</Box>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(Image);
